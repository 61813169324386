import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import {css, withStyles} from 'react-with-styles';

const theme = {
  spacing: {
    unit: 8,
  },
  colors: {
    text: {
      default: 'rgba(0,0,0,.87)',
    },
    grey: {
      default: '#EFEFEF',
      dark: '#808080',
    },
  },
};

ThemedStyleSheet.registerTheme(theme);
ThemedStyleSheet.registerInterface(aphroditeInterface);

export {css, withStyles, ThemedStyleSheet};
