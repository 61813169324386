import React from 'react';
import {Switch, Route} from 'react-router-dom';

import KBoxUID from '../containers/KBox/KBoxUID';
import NotFound from '../containers/NotFound';

export default (
  <Switch>
    <Route exact path="/box" component={KBoxUID} />

    <Route exact path="*" component={NotFound} />
  </Switch>
);
