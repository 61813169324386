import React from 'react';
import {MdDoNotDisturb} from 'react-icons/md';

export default function NotFound() {
  return (
    <div>
      <div style={{textAlign: 'center'}}>
        <MdDoNotDisturb style={{fontSize: '100px', marginTop: '1rem'}} />
        <h1>Cette page n'existe pas !</h1>
        <h2>Erreur 404</h2>
      </div>
    </div>
  );
}

NotFound.propTypes = {};
