// src/App.js
import React from 'react';
import {Router} from 'react-router-dom';
import ReactDOM from 'react-dom';

import history from './history';
import routes from './routes/Index';

// Get mount element for react app
const mountElement = document.getElementById('mount');

const App = () => {
  return <Router history={history}>{routes}</Router>;
};

if (mountElement) {
  ReactDOM.render(<App />, mountElement);
}

export default App;
