import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {css, withStyles} from '../../boot/withStyles';
import Logo from '../../../image/logo512.png';
import {FaRegCopy} from 'react-icons/fa';

class KBoxUID extends Component {
  copyToClipboard = text => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  render() {
    const {styles} = this.props;
    const UUId = this.props.location.search.slice(6);

    return (
      <div {...css(styles.container)}>
        <div {...css(styles.logo)} />
        <div {...css(styles.wrapper)}>
          <img src={Logo} {...css(styles.logo)} />
          <span>
            <h1>KBox</h1>
            <p {...css(styles.p)}>
              Identifiant : <span {...css(styles.mono)}>{UUId}</span>{' '}
              <button
                {...css(styles.button)}
                onClick={() => this.copyToClipboard(UUId)}
              >
                <FaRegCopy />
              </button>
            </p>
          </span>
        </div>
      </div>
    );
  }
}

const styles = () => ({
  container: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
  },

  logo: {
    maxWidth: '160px',
    marginBottom: '2rem',
  },

  wrapper: {
    backgroundColor: 'white',
    padding: '4rem',
    textAlign: 'center',
  },

  p: {
    fontSize: '2rem',
  },

  button: {
    padding: '3px',
    verticalAlign: 'middle',
    cursor: 'copy',
  },

  mono: {
    fontFamily: `"Roboto Mono", monospace`,
  },
});

KBoxUID.propTypes = {
  location: PropTypes.object,
  styles: PropTypes.object,
};

export default withStyles(styles)(KBoxUID);
